import Vue from 'vue'
import App from './App.vue'
import jsCookie from 'js-cookie'
import { VueAxios } from './utils/request'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import enLocale from './lang/en'
import jpnLocale from './lang/jpn'
import zhLocale from './lang/zh'
import ApolloClient from 'apollo-boost'
import VueApollo from 'vue-apollo'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import { Modal,Row,Col,Message,AutoComplete,FormModel,Divider,Spin,Input,Table,Card,Icon,Empty,Space,Popover,Button,collapse,Cascader  } from 'ant-design-vue';
import Vant from 'vant';
import '@vant/touch-emulator';
import 'vant/lib/index.css';
import '../src/static/style/global.css';/*引入公共样式*/
import '../src/static/iconfont/iconfont.css';
import { Lazyload } from 'vant';
// Vue.use(VueAxios)
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(Modal);
Vue.use(FormModel);
Vue.use(Popover)
Vue.use(Vant);
Vue.use(VideoPlayer)
Vue.use(Divider)
Vue.use(Input)
Vue.use(Button);
Vue.use(Button);
Vue.use(collapse)
Vue.use(Cascader)
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Spin.name, Spin);
Vue.component(Table.name, Table);
Vue.component(Card.name, Card);
Vue.component(Icon.name, Icon);
Vue.component(Empty.name, Empty);
Vue.component(Space.name, Space);
Vue.component('a-auto-complete', AutoComplete)
Vue.prototype.$message = Message;
Vue.prototype.$cookie = jsCookie;
const apolloClient = new ApolloClient({
  // 你需要在这里使用绝对路径
  uri: process.env.VUE_APP_GRAPHQL_API_HOST,
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.VUE_APP_SHOPIFY_TOKEN
  }
})
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  
})

Vue.use(VueApollo)
Vue.use(VueI18n)
const i18n = new VueI18n({
	// 默认语言，这里的local属性，对应message中的cn、en属性
	locale: 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	// 引入语言文件
	messages: {
	// 这里的属性名是任意的，您也可以把zh设置为cn等，只是后续切换语言时
	// 要标识这里的语言属性，如：this.$i18n.locale = zh|en|cn|xxx
	'jpn': jpnLocale,
	'en': enLocale,
  'zh': zhLocale
	},
})
Vue.filter('formFilter', (type) => {
  switch (type) {
    case 2:
      return i18n.t('common.register')
    case 3:
      return i18n.t('common.forgot')
    default:
      return i18n.t('common.login')
  }
})
Vue.filter('statusFilter', (status) => {
  switch (status) {
    case 'inactive':
      return i18n.t('enquiry.inactive')
    case 'using':
      return i18n.t('enquiry.using')
    case 'expired':
      return i18n.t('enquiry.expired')
    case 'terminated':
      return i18n.t('enquiry.terminated')
  }
})
Vue.filter('titleFilter', (status) => {
  switch (status) {
    case 2:
      return i18n.t('orders.nonePay')
    case 3:
      return i18n.t('orders.fulfilled')
    case 4:
      return i18n.t('orders.unfulfilled')
    default:
      return i18n.t('orders.all')
  }
})
// Vue.filter('exchangeRate', (price, code) => {
//   switch (code) {
//     case 'HKD':
//       return Math.round(Math.round(price * store.state.ExchangeRate.hkd) / 100 ) * 100
//     case 'USD':
//       return Math.round(Math.round(price * store.state.ExchangeRate.usd) / 100 ) * 100
//     default:
//       return '???'
//   }
// })
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
  if(to.meta.login){
		if(jsCookie.get('TSimAccessToken')){
		  next()
		}else{
      Message.warning(i18n.t('common.loginTips'))
      store.commit('LoginShow', true)
      store.commit('Login', false)
		}
	}else{
		next()
	}
})
router.afterEach((to, form) => {
	switch (i18n.locale) {
		case 'jpn':
			document.title = to.meta.title_jpn
			break;
		default:
			document.title = to.meta.title_en
			break;
	}
})

new Vue({
  router,
  store,
  apolloProvider,
  i18n,
  render: h => h(App)
}).$mount('#app')

