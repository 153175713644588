//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTopsiDataplanList } from '@/api/enquiry'
import progressBar from '@/components/ProgressBar.vue'

export default {
  components: {
    progressBar
  },
  data() {
    return {
      value:null,
      planList:[],
      activeNames: ['1'],
      activeNames2: ['1'],
      pinArray:[],
      activeKey: [],
    }
  },
  created () {
    this.$i18n.locale = 'en'
  },
  methods:{
    isData(array){
      return array.some(item => item.value && item.value.trim() !== '');
    },
    onSearch(value){
      if(!value) {
        this.planList = []
        return
      }
      if (value&&value.length < 14) {
        this.$message.warning('The length of the card number is invalid')
        return
      }
      this.activeKey = []
      getTopsiDataplanList({ sim: value }).then(res => {
        this.planList = []
        if (res.code==1) {
          if(res.result.length==1) this.activeKey.push(res.result[0].card_dataplan_id)
          res.result = res.result.map(item => ({
            isShow:Object.values(item.pin_puk).some(value => value && value.trim() !== ''),
            ...item,
            // pin_puk: Object.entries(item.pin_puk).map(([key, value]) => ({ key, value }))
          }));
          this.planList = res.result
        }else{;
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
