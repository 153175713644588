//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueQr from 'vue-qr'
import {getGygPlanInfo} from "@/api/enquiry";
import Clipboard from "clipboard";
import { ImagePreview } from 'vant';
import progressBar from '@/components/ProgressBar.vue'
export default {
  components: {
    VueQr,
    progressBar
  },
  data() {
    return {
      value: null,
      progress:80,
      activeKey:[],
      activeKey2:[],
      imageEnArray:[
          require('../../assets/WeChatEN.png')
      ],
      imageZhArray:[
          require('../../assets/WeChatZH.png'),
      ],
      isIphone:undefined,
      planList: [],
      isShowlanguage:false,
      EnquirylocaleId:localStorage.getItem('EnquirylocaleId') || 1,
      OptionsArray:[
        {id:0,name:'繁體中文',value:'zh'},
        {id:1,name:'English',value:'en'}
      ],
      showBtn:false,
    };
  },
  beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
  },
  mounted () {
    this.detectDevice()
    this.getVersion()
    document.addEventListener('click', this.handleClickOutside);//监听点击事件是否点击到除了选择语言外的盒子
    if(localStorage.getItem('Enquirylocale') !== null){
      this.$i18n.locale = localStorage.getItem('Enquirylocale')
    }
  },
  methods: {
    // isOpen(item){
    //   return this.activeKey2.includes(item)
    // },
    getVersion(){
        var userAgent = navigator.userAgent;
        var regex = /iPhone OS (\d+[._]\d+[._]?\d*)/;
        var match = userAgent.match(regex);
        if (match && match.length > 1) {
            let versionString = match[1].replace(/_/g, '.');
            let version = parseFloat(versionString);
            if (version > 17.3) {
                this.showBtn = true;
            }
        } else {
            console.error("未能从 userAgent 中找到 iPhone 系统版本号");
        }
    },
    href(item){
      window.location.href='https://esimsetup.apple.com/esim_qrcode_provisioning?carddata='+item.lpa_str
    },
    previewImage(lang,index) {
      if(lang=='zh'){
        ImagePreview({
              images: this.imageZhArray,
              showIndicators: true
          });
      }else{
        ImagePreview({
              images: this.imageEnArray,
              showIndicators: true
          });
      } 
    },
    detectDevice(){
                let userAgent = navigator.userAgent;
                if (/android/i.test(userAgent)) {
                    this.isIphone = true
                }else this.isIphone = false
     },
    selectLanguage(item){//选择语言
        localStorage.setItem('Enquirylocale',item.value);
        localStorage.setItem('EnquirylocaleId',item.id);
        this.isShowlanguage = false;
        this.EnquirylocaleId = item.id;
        console.log(this.EnquirylocaleId);
        this.$i18n.locale = item.value;
    },
    handleClickOutside(event) {
      const languageBox = document.querySelector('.languageBox');
      if (languageBox && !languageBox.contains(event.target)) {
        this.isShowlanguage = false;
      }
    },
    showLanguage(){
      this.isShowlanguage = !this.isShowlanguage
    },
    copy() {
      let clipboard = new Clipboard('.info_adress')
      clipboard.on('success', e => {
        // 释放内存
        this.$message.success(this.$t('dataplanenquiry.copied'))
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        // 释放内存
        this.$message.error(this.$t('dataplanenquiry.copyError'))
        clipboard.destroy()
      })
    },
    onSearch(value) {
      if (!value) {
        this.planList = [];
        return;
      }
      this.activeKey = []
      getGygPlanInfo({reference: value}).then((res) => {
        this.planList = [];
        if (res.code == 1) {
          // this.planList = res.result;
          if(res.result.length==1) this.activeKey.push(res.result[0].card_dataplan_id)
          this.planList = res.result.map((item) => {
            const lpaStrParts = item.lpa_str.split("$");
            const lpa = lpaStrParts[0];
            const smdp_address = lpaStrParts[1];
            const activation_code = lpaStrParts[2];
            return {
              isShow:Object.values(item.pin_puk).some(value => value && value.trim() !== ''),
              ...item,
              // pin_puk: Object.entries(item.pin_puk).map(([key, value]) => ({ key, value })),
              lpa,
              smdp_address,
              activation_code,
            };
          });
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
