//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


 function CheckIsColor(bgVal) {
      let type='';
      if(/^rgb\(/.test(bgVal)){
          //如果是rgb开头，200-249，250-255，0-199
         type = "^[rR][gG][Bb][\(]([\\s]*(2[0-4][0-9]|25[0-5]|[01]?[0-9][0-9]?)[\\s]*,){2}[\\s]*(2[0-4]\\d|25[0-5]|[01]?\\d\\d?)[\\s]*[\)]{1}$";
      }else if(/^rgba\(/.test(bgVal)){
          //如果是rgba开头，判断0-255:200-249，250-255，0-199 判断0-1：0 1 1.0 0.0-0.9
         type = "^[rR][gG][Bb][Aa][\(]([\\s]*(2[0-4][0-9]|25[0-5]|[01]?[0-9][0-9]?)[\\s]*,){3}[\\s]*(1|1.0|0|0.[0-9])[\\s]*[\)]{1}$";
      }else if(/^#/.test(bgVal)){
          //六位或者三位
         type = "^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$"
      }else if(/^hsl\(/.test(bgVal)){
          //判断0-360 判断0-100%(0可以没有百分号)
         type = "^[hH][Ss][Ll][\(]([\\s]*(2[0-9][0-9]|360｜3[0-5][0-9]|[01]?[0-9][0-9]?)[\\s]*,)([\\s]*((100|[0-9][0-9]?)%|0)[\\s]*,)([\\s]*((100|[0-9][0-9]?)%|0)[\\s]*)[\)]$";
      }else if(/^hsla\(/.test(bgVal)){
          type = "^[hH][Ss][Ll][Aa][\(]([\\s]*(2[0-9][0-9]|360｜3[0-5][0-9]|[01]?[0-9][0-9]?)[\\s]*,)([\\s]*((100|[0-9][0-9]?)%|0)[\\s]*,){2}([\\s]*(1|1.0|0|0.[0-9])[\\s]*)[\)]$";
      }
      let re = new RegExp(type);
      if (bgVal.match(re) == null){
         return false
      }else{
         return true
     }
    }
export default {
    name:'progressBar',
    props:{
        record:{
            type:Object,
            default:()=>{}
        },
        status:{
            type:String,
            default:'using'
        },
        sizeOut:{
            type:Number,
            default:100
        },
        sizeIn:{
            type:Number,
            default:120,
        },
        size:{
            type:Number,
            default:20
        },
        colorBg:{
            type:String,
            default:'#fff',
            // validator:function(value){
            //     CheckIsColor(value)
            // }
        },
        colorTop:{
            type:String,
            default:'#dd007f',
            // validator:function(value){
            //     CheckIsColor(value)
            // }
        },
        colorBottom:{
            type:String,
            default:'#959595',
            //  validator:function(value){
            //     CheckIsColor(value)
            // }
        },
        target:{
            type:String,
            default:0
        },
        total:{
            type:Number,
            default:100
        },
        index:{
            type:String,
            default:0
        },
        textSize:{
            type:Number,
            default:21
        },
        percentageSize:{
            type:Number,
            default:30,
        },
        hasMask:{
            type:Boolean,
            default:false
        },
        encumbrances:{
            type:Number,
            default:2
        },
        statusColor:{
            type:String,
            default:'#FFFFFF',
        },
        statusBgColor:{
            type:String,
            default:'#00a41b',
        },
        usageColor:{
            type:String,
            default:'#dd007f',
        },
        totalUsageColor:{
            type:String,
            default:'#666666',
        },
        defaultBgColor:{
            type:String,
            default:'#EAEAEA'
        },
        defaultTipColor:{
            type:String,
            default:'#000000'
        },
        DaysColor:{
            type:String,
            default:'#00a41b'
        }
    },
    mounted () {
        this.$nextTick(()=>{
            let formatted = (this.index / 1000).toFixed(2);
                        console.log(formatted,'1');
                        console.log(formatted.endsWith('.00'),'2');
                        console.log(`${parseInt(formatted)}GB`,'3');
        })
    },
    computed:{
        show: function(){
            if(this.target){
                return ((100-this.target)*2*this.sizeIn*3.14/100).toFixed(this.encumbrances)
            }else{
                return ((1-this.index/this.total)*2*this.sizeIn*3.14).toFixed(this.encumbrances)
            }
        },
        percentage: function(){
            if(this.target){
                return this.target.toFixed(this.encumbrances)
            }else{
               return ((this.index/this.total)*100).toFixed(this.encumbrances)
            }
        },
        formattedTime() {
            let totalSeconds = this.record.expire_seconds;
            // 计算天数
            const days = Math.floor(totalSeconds / (24 * 3600));
            totalSeconds %= (24 * 3600);
            // 计算小时
            const hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            // 计算分钟
            const minutes = Math.floor(totalSeconds / 60);
            // 剩余的秒数
            const seconds = totalSeconds % 60;
            // 格式化时间字符串 
            return `${days} ${this.$t('dataplanenquiry.days')} ${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
        },
        formattedusagelData() {
            if (this.index < 0 || Math.sign(this.index) === -1) {
                return '0 MB';
            } else {
                // 判断是否使用 gb1000 转换
                if (this.record.gb1000) {
                    if (this.index >= 1000) {
                        // 大于1000MB，转为GB并去掉小数点后零
                        let formatted = (this.index / 1000).toFixed(2);
                        return formatted.endsWith('.00') ? `${parseInt(formatted)}GB` : `${formatted}GB`;
                    } else {
                        // 小于1000MB，保留两位小数
                        let formatted = this.index.toFixed(2);
                        return formatted.endsWith('.00') ? `${parseInt(formatted)}MB` : `${formatted}MB`;
                    }
                } else {
                    // 使用1024MB转为GB
                    if (this.index > 1023) {
                        let formatted = (this.index / 1024).toFixed(2);
                        return formatted.endsWith('.00') ? `${parseInt(formatted)}GB` : `${formatted}GB`;
                    } else {
                        let formatted = this.index.toFixed(2);
                        return formatted.endsWith('.00') ? `${parseInt(formatted)}MB` : `${formatted}MB`;
                    }
                }
            }
            // return this.record.gb1000?this.total >= 1000 ? `${(this.total / 1000).toFixed(2)}GB ${this.record.spec_type=='DAILY'?this.$t('dataplanenquiry.daliy'):''}` : `${this.total.toFixed(2)}MB ${this.record.spec_type=='DAILY'?this.$t('dataplanenquiry.daliy'):''}` : this.total > 1023 ? `${(this.total / 1024).toFixed(2)}GB ${this.record.spec_type=='DAILY'?this.$t('dataplanenquiry.daliy'):''}` : `${this.total.toFixed(2)}MB ${this.record.spec_type=='DAILY'?this.$t('dataplanenquiry.daliy'):''}`;
        },
        formattedTotalData() {
            if (this.record.gb1000) {
                // 使用 gb1000 转换
                if (this.total >= 1000) {
                    let formatted = (this.total / 1000).toFixed(2);
                    return formatted.endsWith('.00') ? `${parseInt(formatted)}GB ` : `${formatted}GB `;
                } else {
                    let formatted = this.total.toFixed(2);
                    return formatted.endsWith('.00') ? `${parseInt(formatted)}MB ` : `${formatted}MB `;
                }
            } else {
                // 使用 1024MB 转换
                if (this.total > 1023) {
                    let formatted = (this.total / 1024).toFixed(2);
                    return formatted.endsWith('.00') ? `${parseInt(formatted)}GB ` : `${formatted}GB `;
                } else {
                    let formatted = this.total.toFixed(2);
                    return formatted.endsWith('.00') ? `${parseInt(formatted)}MB ` : `${formatted}MB `;
                }
            }
        },
    },
    data () {
        return {
            
        }
    },
    methods: {
        padZero(number) {
            return number < 10 ? `0${number}` : `${number}`;
        }
    }
}
