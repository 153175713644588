//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueQr from 'vue-qr'
import Clipboard from 'clipboard'; 
import { getDataplanInfo } from '@/api/enquiry'
export default {
  components: {VueQr},
  data() {
    return {
      spinning:false,
      emptyText:'',
      isEmpty:false,
      showBtn:false,
      info:{
        lpa_str:''
      }
    }
  },
  mounted(){
    this.spinning = true
    getDataplanInfo({card_dataplan_id:this.$route.params.id}).then(res => {
      this.spinning = false
      if (res.code==1) {
        this.isEmpty = true;
        res.result = {
            ...res.result,
            pin_puk: Object.entries(res.result.pin_puk).map(([key, value]) => ({ key, value }))
          };
        this.info = res.result;
      }else{
        this.isEmpty = false;
        this.emptyText = res.msg
        this.$message.warning(res.msg)
      }
    })
    this.getVersion()
  },
  methods:{
    isData(array){
      return array.some(item => item.value && item.value.trim() !== '');
    },
    getVersion(){
        var userAgent = navigator.userAgent;
        var regex = /iPhone OS (\d+[._]\d+[._]?\d*)/;
        var match = userAgent.match(regex);
        if (match && match.length > 1) {
            let versionString = match[1].replace(/_/g, '.');
            let version = parseFloat(versionString);
            if (version > 17.3) {
                this.showBtn = true;
            }
        } else {
            console.error("未能从 userAgent 中找到 iPhone 系统版本号");
        }
    },
    href(){
      window.location.href='https://esimsetup.apple.com/esim_qrcode_provisioning?carddata='+this.info.lpa_str
    },
    copy () {  
      let clipboard = new Clipboard('.copy')  
      clipboard.on('success', e => {  
        // 释放内存  
        this.$message.success('Copy already')
        clipboard.destroy()  
      })  
      clipboard.on('error', e => {  
        // 不支持复制  
        // 释放内存  
        this.$message.error('Copy failed')
        clipboard.destroy()  
      })  
    },
  }
}
